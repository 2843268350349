import '../styles/variant-zs.scss'
import $ from 'jquery'
import Swiper, { Navigation } from 'swiper';
import UAParser from "ua-parser-js"
import {elementExists} from "./helpers"
import "lightgallery.js"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import csLocale from '@fullcalendar/core/locales/cs';
import L from "leaflet"

let parser = new UAParser()
let parserResult = parser


Swiper.use([Navigation]);

$(function() {

    if (elementExists("#map")) {
        var map = L.map('map', {
            center: [50.102, 14.427],
            zoom: 14
        });

        L.tileLayer('https://mapserver.mapy.cz/base-m/{z}-{x}-{y}', {
            attribution: '&copy; <a href="mapy.cz">Mapy.cz</a>'
        }).addTo(map);

        L.marker([50.102, 14.427]).addTo(map)
            .bindPopup('ZŠ Umělecká, Praha 7')
            .openPopup();
    }

    if (elementExists("#entryvideo")) {
        if (parserResult.getDevice().type !== "mobile") {
            $("#entryvideo").html("<source src='images/homepage.mp4' type='video/mp4'>")
        }
    }

    if (elementExists("#lightgallery")) {
        lightGallery(document.getElementById('lightgallery'))
    }

    if (elementExists(".swiper-container")) {
        const swiper = new Swiper(".swiper-container", {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.swiper__arrow-next',
                prevEl: '.swiper__arrow-prev',
                disabledClass: 'd-none',
                hiddenClass: 'd-none',
            },
            breakpoints: {
                // when window width is >= 768px
                896: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    slidesPerGroup: 3,
                    navigation: {
                        nextEl: '.swiper__arrow-next',
                        prevEl: '.swiper__arrow-prev',
                        disabledClass: 'd-none',
                        hiddenClass: 'd-none',
                    },
                }
            },
        })
    }

    if (elementExists("#calendar")) {
        let calendarEl = document.getElementById('calendar');
        let calendar = new Calendar(calendarEl, {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
            initialView: 'dayGridMonth',
            locales: [ csLocale ],
            locale: 'cs',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listWeek'
            }
        });
        calendar.render();

    }

})
